import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { HiClipboard, HiOutlineRefresh } from "react-icons/hi";
import API from "../api";

const Stats = ({}) => {
  const [invite, setInvite] = React.useState({ email: "", admin: false });
  const [disabled, setDisabled] = React.useState(false);
  const [keys, setKeys] = React.useState([]);

  const inviteUser = async () => {
    setDisabled(true);
    try {
      const res = await API.post("/user/create", invite);
      if (!res.ok) return toast.error(res.message);
      toast.success("L'utilisateur a été invité avec succès");
      setInvite({ email: "", admin: false });
    } catch (error) {
      toast.error(error.message);
    }
    setDisabled(false);
  };

  const fetchKeys = async () => {
    try {
      const res = await API.get("/apikey");
      if (!res.ok) return toast.error(res.message);
      console.log(res.data);

      setKeys(res.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const refreshKeys = async (id, type) => {
    const key = keys.find((key) => key._id === id);
    const confirm = window.confirm(`Voulez-vous vraiment réinitialiser la clé "${key[type]}" ?`);
    if (!confirm) return;

    try {
      const res = await API.post(`/apikey/${id}/refresh/${type}`);
      if (!res.ok) return toast.error(res.message);

      toast.success(`La clé a été réinitialisée avec succès.`);
      fetchKeys();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const toggleKeyStatus = async (id, disabled) => {
    try {
      const action = disabled ? "désactiver" : "activer";
      const confirm = window.confirm(`Voulez-vous vraiment ${action} cette clé d'API ?`);
      if (!confirm) return;

      const res = await API.post(`/apikey/${id}/disable`, { disabled });
      if (!res.ok) return toast.error(res.message);

      toast.success(`La clé a été ${disabled ? "désactivée" : "activée"} avec succès.`);
      fetchKeys();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchKeys();
  }, []);

  return (
    <div className="px-4">
      <div className="flex justify-between gap-4 h-[calc(100vh-200px)]">
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="w-full max-w-lg">
            <h1 className="text-2xl font-bold text-center w-full">Inviter un utilisateur</h1>
            <p className="text-center w-full">Un email sera envoyé à l'utilisateur pour qu'il puisse créer un compte et accéder à l'application.</p>
            <input
              type="text"
              className="mt-2 p-2 w-full border rounded-md"
              placeholder="Adresse e-mail"
              value={invite.email}
              onChange={(e) => setInvite({ ...invite, email: e.target.value })}
            />
            <div className="flex items-center justify-between mt-4">
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => setInvite({ ...invite, admin: !invite.admin })}
                  className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#026D77] focus:ring-offset-2 ${
                    invite.admin ? "bg-[#026D77]" : "bg-gray-200"
                  }`}
                >
                  <span
                    className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                      invite.admin ? "translate-x-5" : "translate-x-0"
                    }`}
                  />
                </button>
                <span className={`ml-2 ${invite.admin ? "font-semibold" : "text-gray-500"}`}>Administrateur</span>
              </div>
              <button
                type="button"
                disabled={disabled}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#026D77] text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#026D77] sm:ml-3 sm:w-auto sm:text-sm"
                onClick={inviteUser}
              >
                Inviter
              </button>
            </div>
          </div>
        </div>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <h2 className="text-2xl font-bold mb-4">Clés d'accès</h2>
          {keys.map((key) => (
            <div key={key._id} className="w-full max-w-lg border rounded-md p-4 relative">
              <div className="space-y-4">
                <div className="flex items-center justify-end mb-4 gap-2">
                  <button
                    type="button"
                    onClick={() => toggleKeyStatus(key._id, !key.disabled)}
                    className={`relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-[#026D77] focus:ring-offset-1 ${
                      key.disabled ? "bg-red-500" : "bg-green-500"
                    }`}
                  >
                    <span
                      className={`pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                        !key.disabled ? "translate-x-4" : "translate-x-0"
                      }`}
                    />
                  </button>
                  <span className={`text-sm font-medium ${key.disabled ? "text-red-500" : "text-green-500"}`}>{key.disabled ? "Désactivée" : "Activée"}</span>
                </div>
                <div className={`${key.disabled ? "opacity-50 pointer-events-none cursor-not-allowed" : ""}`}>
                  <div className="flex items-center justify-between mx-1">
                    <p className="text-sm font-medium text-gray-500">Clé n°1</p>
                    <button type="button" onClick={() => refreshKeys(key._id, "apikey")}>
                      <HiOutlineRefresh className="text-gray-500" />
                    </button>
                  </div>
                  <div className="mt-1 p-2 bg-gray-100 rounded-md text-sm font-mono break-all flex items-center justify-between">
                    {key.apikey}
                    <button
                      type="button"
                      className="text-xl"
                      onClick={() => {
                        navigator.clipboard.writeText(key.apikey);
                        toast.success("Clé n°1 copiée");
                      }}
                    >
                      <HiClipboard className="text-[#026D77]" />
                    </button>
                  </div>
                </div>
                <div className={`${key.disabled ? "opacity-50 pointer-events-none cursor-not-allowed" : ""}`}>
                  <div className="flex items-center justify-between mx-1">
                    <p className="text-sm font-medium text-gray-500">Clé n°2</p>
                    <button type="button" onClick={() => refreshKeys(key._id, "apikey2")}>
                      <HiOutlineRefresh className="text-gray-500" />
                    </button>
                  </div>
                  <div className="mt-1 p-2 bg-gray-100 rounded-md text-sm font-mono break-all flex items-center justify-between">
                    {key.apikey2}
                    <button
                      type="button"
                      className="text-xl"
                      onClick={() => {
                        navigator.clipboard.writeText(key.apikey2);
                        toast.success("Clé n°2 copiée");
                      }}
                    >
                      <HiClipboard className="text-[#026D77]" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <p className="text-sm text-red-500 w-full max-w-lg mt-4">
            La réinitialisation d'une clé d'accès entraîne les conséquences suivantes :
            <ol className="list-decimal pl-5 mt-2 space-y-1">
              <li>L'ancienne clé sera immédiatement désactivée.</li>
              <li>Une nouvelle clé sera générée pour la remplacer.</li>
              <li>Toutes les applications ou extensions utilisant l'ancienne clé perdront l'accès aux services.</li>
              <li>Assurez-vous de mettre à jour la clé dans tous vos systèmes après une réinitialisation.</li>
            </ol>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Stats;
