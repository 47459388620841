import "isomorphic-fetch";

import { API_URL } from "./config";

class api {
  constructor() {
    this.token = "";
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  get(path) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_URL}${path}`, {
          mode: "cors",
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.token}`,
          },
        });

        if (response.status === 401) return reject({ code: 401, message: "Unauthorized" });

        const res = await response.json();
        if (response.status !== 200) return reject(res);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });
  }

  put(path, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_URL}${path}`, {
          mode: "cors",
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.token}`,
          },
          body: JSON.stringify(body),
        });

        const res = await response.json();
        if (response.status !== 200) return reject(res);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });
  }

  putFormData(path, body, files) {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(files[i].name, files[i], files[i].name);
    }
    formData.append("body", JSON.stringify(body));

    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_URL}${path}`, {
          mode: "cors",
          method: "PUT",
          credentials: "include",
          headers: { Authorization: `JWT ${this.token}` },
          body: formData,
        });

        const res = await response.json();
        if (response.status !== 200) return reject(res);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });
  }

  postFormData(path, formData) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_URL}${path}`, {
          mode: "cors",
          method: "POST",
          credentials: "include",
          headers: { Authorization: `JWT ${this.token}` },
          body: formData,
        });
        const res = await response.json();
        if (response.status !== 200) return reject(res);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });
  }

  delete(path, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_URL}${path}`, {
          mode: "cors",
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.token}`,
          },
          body: JSON.stringify(body),
        });

        const res = await response.json();
        if (response.status !== 200) return reject(res);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });
  }

  post(path, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_URL}${path}`, {
          mode: "cors",
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.token}`,
          },
          body: JSON.stringify(body),
        });

        const res = await response.json();
        if (response.status !== 200) return reject(res);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });
  }

  download(path) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${API_URL}${path}`, {
          mode: "cors",
          method: "GET",
          credentials: "include",
          headers: { Authorization: `JWT ${this.token}` },
        });

        const res = await response.blob();
        if (response.status !== 200) return reject(res);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });
  }
}

const service = new api();
export default service;
