import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory, NavLink, Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as Sentry from "@sentry/browser";
import { HiExternalLink, HiUserAdd, HiOutlineLogout } from "react-icons/hi";

import { environment, SentryUrl } from "./config";
import API from "./api";
import Loading from "./components/Loading";

import Auth from "./scenes/auth";
import ResetPassword from "./scenes/resetPassword";
import Annotation from "./scenes/annotation";
import Code from "./scenes/code";
import Stats from "./scenes/stats";
import Rules from "./scenes/rules";
import Explorer from "./scenes/explorer";
import Settings from "./scenes/settings";
import Sandbox from "./scenes/sandbox";

if (environment === "production" || environment === "staging") {
  Sentry.init({ dsn: SentryUrl });
}

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await API.get("/user/signin_token");
        if (!res.ok || !res.user || !res.token) return;

        API.setToken(res.token);
      } catch (e) {
        if (e.code !== 401) toast.error(e.message);
      }

      setLoading(false);
    })();
  }, []);

  if (loading)
    return (
      <div className="flex-center h-screen w-full">
        <Loading />
      </div>
    );

  return (
    <Router>
      <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/reset-password" component={ResetPassword} />
        <RestrictedRoute path="/annotation" component={Annotation} />
        <RestrictedRoute path="/stats" component={Stats} />
        <RestrictedRoute path="/rules" component={Rules} />
        <RestrictedRoute path="/explorer" component={Explorer} />
        <RestrictedRoute path="/settings" component={Settings} />
        <RestrictedRoute path="/querco/sandbox" component={Sandbox} setLoading={setLoading} />
        <RestrictedRoute path="/" exact component={Code} setLoading={setLoading} />
      </Switch>
    </Router>
  );
};

const RestrictedRoute = ({ component: Component, setLoading, superadmin, ...rest }) => {
  const redirect = encodeURIComponent(window.location.href.replace(window.location.origin, ""));
  const history = useHistory();
  const [user, setUser] = useState({ role: "user" });

  const getCurrentUser = async () => {
    try {
      const { data } = await API.get("/user/me");

      if (!data) return;

      setUser(data);
    } catch (error) {
      if (error.code === 401) return;
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const logout = async () => {
    try {
      await API.post("/user/logout");
      API.setToken("");
      history.push("/");
    } catch (error) {
      toast.error(error.message);
    }
  };

  let render = (props) => (
    <div>
      <div className="text-[#026D77] p-4 flex justify-between items-start">
        <div>
          <Link to="/" className="text-2xl font-bold">
            Querco - Extraction
          </Link>
          {(user.role === "admin" || user.role === "superadmin") && (
            <div className="flex items-end gap-4 mt-2">
              <NavLink to="/" exact className={(isActive) => (isActive ? "underline underline-offset-4" : "")}>
                Table
              </NavLink>
              <NavLink to="/rules" className={(isActive) => (isActive ? "underline underline-offset-4" : "")}>
                Règles d'insertion
              </NavLink>
              <NavLink to="/stats" className={(isActive) => (isActive ? "underline underline-offset-4" : "")}>
                Statistiques
              </NavLink>
              <NavLink to="/explorer" className={(isActive) => (isActive ? "underline underline-offset-4" : "")}>
                Explorateur
              </NavLink>
              {/* // TODO : Uncomment this
              <NavLink to="/settings" className={(isActive) => (isActive ? "underline underline-offset-4" : "")}>
                Paramètres
              </NavLink> */}
            </div>
          )}
        </div>
        {user.role === "superadmin" && <div className="text-red-500">Indexname : {user?.indexName}</div>}
        <div className="flex gap-4 items-center">
          {(user.role === "admin" || user.role === "superadmin") && (
            <>
              <button type="button" onClick={() => window.open("https://help.querco.co/", "_blank")} className="flex gap-2 items-center">
                <HiExternalLink />
                Manuel d'utilisation
              </button>
            </>
          )}
          <button type="button" onClick={logout} className="flex gap-2 items-center">
            <HiOutlineLogout />
            Se deconnecter
          </button>
        </div>
      </div>
      <Component {...props} setLoading={setLoading} user={user} />
    </div>
  );

  if (!API.getToken()) {
    render = (props) => (
      <Redirect
        to={{
          search: redirect ? `?redirect=${redirect}` : "",
          pathname: "/auth",
        }}
      />
    );
  }

  return <Route {...rest} render={render} />;
};

export default App;
